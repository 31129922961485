import { NotificationBar } from "./components/NotificationBar"
import { initBestSellersSlider, initShopNewInTabSlider, initRelatedProductsSlider } from "./components/swipers"
import { initHeightEqualizer } from "./shared"
import { triggerQuickBuy } from "./product/quickBuy"
import { initFiltersToggle } from "./pages/catalog"
import { initDriftZoom } from "./components/driftZoom"
import { productPageInitializer } from "./initializers/productPageInitializer"



export function bindGYEvents() {
  window.gyEvents = {
    /**
     * Triggered when options (color, size etc) for given product start loading.
     * @param {event} the event that was triggered
     */
    optionsStartLoading: function (event) { },

    /**
     * Triggered when options (color, size etc) for given product are loaded.
     * @param {event} the event that was triggered
     */
    optionsLoaded: function (event) { },

    optionsLoaded: function (event) { },

    optionVariantChanged: function (event) { },

    /**
     * Triggered when photos of product have been changed.
     * @param {event} the event that was triggered
     */
    photosChanged: function (event) {
      productPageInitializer()
    },

    /**
     * Triggered when options of product have been changed.
     * @param {event} the event that was triggered
     */
    optionVariantChanged: function (event) { },

    /**
     * Triggered when something is added to cart.
     * @param {event} the event that was triggered
     */
    addedToCart: function (event) {
      new NotificationBar(
        "success",
        event.detail.message
      )

      document.querySelectorAll(".quick-buy--active").forEach((activeQuickBuy) => {
        activeQuickBuy.classList.remove("quick-buy--active")
      })
    },

    /**
     * Triggered when something cannot be added to cart.
     * @param {event} the event that was triggered
     */
    cannotAddToCart: function (event) {
      new NotificationBar(
        "error",
        event.detail.message
      )
    },

    /**
     * Triggered when a 401 is returned from specific actions. For example when user tries to adds a
     * product to wishlist and he isn"t already logged in.
     * Used to display login popup form.
     * @param {event} the event that was triggered
     */
    userNeedsLogin: function (event) {
      new NotificationBar(
        "error",
        event.detail.message
      )
    },

    /**
     * Triggered when wishlist is loaded.
     * @param {event} the event that was triggered
     */
    wishlistLoaded: function (event) { },

    bundlesLoaded: function (event) { },

    /**
     * Triggered when a wishlist item is deleted.
     * @param {event} the event that was triggered
     */
    wishlistItemDeleted: function (event) {
      new NotificationBar(
        "success",
        event.detail.message
      )
    },

    /**
     * Triggered when ALL wishlist items are deleted.
     * @param {event} the event that was triggered
     */
    wishlistDeletedAll: function (event) {
      new NotificationBar(
        "success",
        event.detail.message
      )
    },

    lineItemDeleted: function (event) {
      new NotificationBar(
        "success",
        event.detail.message
      )
    },

    /**
     * Triggered when a wishlist item is added to cart.
     * @param {event} the event that was triggered
     */
    wishlistAddedToCart: function (event) {
      new NotificationBar(
        "success",
        event.detail.message
      )
    },

    /**
     * Triggered when ALL wishlist items are added to cart.
     * @param {event} the event that was triggered
     */
    wishlistAddedAllToCart: function (event) {
      new NotificationBar(
        "success",
        event.detail.message
      )
    },

    /**
     * Triggered when a product is added to wishlist. Usefull to show wishlist to user.
     * @param {event} the event that was triggered
     */
    addedToWishlist: function (event) {
      new NotificationBar(
        "success",
        event.detail.message
      )
    },

    /**
     * Triggered when a product already exists in wishlist. Usefull to show wishlist to user, and message.
     * @param {event} the event that was triggered
     */
    alreadyExistsInWishlist: function (event) {
      new NotificationBar(
        "error",
        event.detail.message
      )
    },

    /**
     * Triggered when a user creates a giftlist.
     * @param {event} the event that was triggered
     */
    giftlistCreated: function (event) { },

    /**
     * Triggered when a user cannot create a giftlist.
     * @param {event} the event that was triggered
     */
    giftlistNotCreated: function (event) { },

    /**
     * Triggered when the giftlist is updated.
     * @param {event} the event that was triggered
     */
    giftlistUpdated: function (event) { },

    /**
     * Triggered when a product is added to giftlist.
     * @param {event} the event that was triggered
     */
    giftlistProductAdded: function (event) { },

    /**
     * Triggered when a product cannot be added to giftlist.
     * @param {event} the event that was triggered
     */
    giftlistProductNotAdded: function (event) { },

    /**
     * Triggered when the giftlist is loaded.
     * @param {event} the event that was triggered
     */
    giftlistsLoaded: function (event) { },


    giftlistFormPopulated: function (event) { },

    /**
     * Triggered when wishlist is deleted.
     * @param {event} the event that was triggered
     */
    giftlistDeleted: function (event) { },

    /**
     * Triggered when a giftlist item is added.
     * @param {event} the event that was triggered
     */
    giftlistItemAdded: function (event) { },

    /**
     * Triggered when a giftlist item is removed.
     * @param {event} the event that was triggered
     */
    giftlistItemRemoved: function (event) { },

    /**
     * Triggered when a giftlist item is added.
     * @param {event} the event that was triggered
     */
    giftlistSetCompleted: function (event) { },

    giftlistShare: function (event) { },

    giftlistSetCompletedError: function (event) { },

    giftlistShareLinkSuccess: function (event) { },

    giftlistShareLinkError: function (event) { },

    /**
     * Triggered when user successfully logs in (after completing username & password in login form).
     * @param {event} the event that was triggered
     */
    userLoggedIn: function (event) {
      // Google Analytics 4
      if (typeof dataLayer !== 'undefined') {
        // Clear the previous ecommerce object.
        dataLayer.push({ ecommerce: null })

        // Push the Sign In event to Data Layer
        dataLayer.push({ event: 'login' })
      }

      window.location.reload()
    },

    /**
     * Triggered when user enters incorrect login details (wrong username and/or password).
     * @param {event} the event that was triggered
     */
    userNotAuthorized: function (event) {
      new NotificationBar(
        "error",
        event.detail.message
      )
    },

    /**
     * Triggered when user successfully registers.
     * @param {event} the event that was triggered
     */
    userRegistered: function (event) {
      // Google Analytics 4
      if (typeof dataLayer !== 'undefined') {
        // Clear the previous ecommerce object.
        dataLayer.push({ ecommerce: null })

        // Push the Sign Up event to Data Layer
        dataLayer.push({ event: 'sign_up' })
      }

      window.location.reload()
    },

    /**
     * Triggered when user didn"t register, due to validation errors (wrong password length etc).
     * @param {event} the event that was triggered
     */
    userNotRegistered: function (event) { },

    /**
     * Triggered when app is mounted.
     * @param {event} the event that was triggered
     */
    appMounted: function (event) { },

    /**
     * Triggered when the visitor clicks on a specific filter through AJAX
     * @param {event} the event that was triggered
     */
    catalogVisitStarted: function (event) { },

    productListLoaded: function(event) {
      requestIdleCallback(() => {
        initHeightEqualizer()
        triggerQuickBuy()
        productPageInitializer()
        initShopNewInTabSlider()
        initBestSellersSlider()
        initRelatedProductsSlider()
      })
    },

    /**
     * Triggered when the visitor clicks on a specific filter through AJAX
     * @param {event} the event that was triggered
     */
    catalogVisitDone: function (event) {
      triggerQuickBuy()
      window.catalogFilters()
      window.unfreezeBackground()
      initFiltersToggle()
    },

    /**
     * Triggered when next page is asked using AJAX.
     * @param {event} the event that was triggered
     */
    catalogPaginate: function (event) { },

    /**
     * Triggered when we have gotten to last page of catalog.
     * @param {event} the event that was triggered
     */
    catalogLastPage: function (event) { },

    couponActivated: function (event) { },

    couponInvalid: function (event) { },

    couponeactivated: function (event) { },

    /**
     * Triggered when the minicart quantity is updated
     * @param {event} the event that was triggered
     */
    lineItemQuantityUpdated: function (event) { },

    lineItemMaxQuantityReached: function (event) { },

    paymentMethodsLoaded: function (event) { },

    shippingMethodsLoaded: function (event) { },

    countriesLoaded: function (event) { },

    regionsLoaded: function (event) { },

    vatOfficesLoaded: function (event) { },

    bundlesLoaded: function (event) {
      triggerQuickBuy()
    },

    bundleCannotAddToCart: function (event) {
      document.querySelectorAll(".promotions .quick-buy").forEach(item => {
        item.classList.add("quick-buy--active")
      })
    },

    bundleAddedToCart: function (event) {
      new NotificationBar(
        "success",
        event.detail.message
      )
    },

    storeFilterSelected: function (event) { },

    addressFormPopulated: function (event) { },

    addressDeleted: function (event) { },

    addressErrorDeleted: function (event) { },

    addressUpdated: function (event) { },

    addressCreated: function (event) { },

    checkoutAddressPopulated: function (event) { },

    promotionsLoaded: function (event) { },

    promotionAddedToCart: function (event) { },

    savedOrdersLoaded: function (event) { },

    savedOrderCreated: function (event) {
      new NotificationBar(
        "success",
        event.detail.message
      )
    },

    savedOrderNotCreated: function (event) {
      new NotificationBar(
        "error",
        event.detail.message
      )
    },

    savedOrderLoaded: function (event) { },

    savedOrderUpdated: function (event) {
      new NotificationBar(
        "success",
        event.detail.message
      )
    },

    savedOrderDeleted: function (event) {
      new NotificationBar(
        "success",
        event.detail.message
      )
    },

    savedOrderAddedToCart: function (event) {
      new NotificationBar(
        "success",
        event.detail.message
      )
    },

    savedLineItemAlreadyExists: function (event) {
      new NotificationBar(
        "error",
        event.detail.message
      )
    },

    savedOrderNoLineItemsOnSave: function (event) {
      new NotificationBar(
        "error",
        event.detail.message
      )
    },

    savedOrderQuickAddedToCart: function (event) {
      new NotificationBar(
        "success",
        event.detail.message
      )
    },

    savedOrderQuickNotUpdated: function (event) {
      new NotificationBar(
        "error",
        event.detail.message
      )
    },

    savedOrderQuickNotSaved: function (event) {
      new NotificationBar(
        "error",
        event.detail.message
      )
    },

    savedOrderCartSaved: function (event) {
      new NotificationBar(
        "success",
        event.detail.message
      )
    },

    savedOrderCartNotSaved: function (event) {
      new NotificationBar(
        "error",
        event.detail.message
      )
    },

    quickOrderAddedToCart: function (event) {
      new NotificationBar(
        "success",
        event.detail.message
      )
    },

    notifyMeWhenBackInStockStarted: function (event) { },

    notifyMeWhenBackInStockCompleted: function (event) { },

    notifyMeWhenBackInStockError: function (event) { },

    mixNMatchItemTaxonomyChanged: function (event) { },

    mixNMatchItemProductsLoaded: function (event) { },

    mixNMatchCannotAddToCart: function (event) { },

    mixNMatchAddedToCart: function (event) { },

    productStoreAvailabilityStarted: function (event) { },

    productStoreAvailabilityCompleted: function (event) { },

    productStoreAvailabilityError: function (event) { },

    initializeGiftMakerSelectionLimiter: function (event) { }
  }
}
